(function($) {$.fn.inviewClass = function(options){var settings = $.extend({className: 'is-inview', secondClassName: 'is-inview-done', visibleOffset: 200, secondClassInterval: 1000 }, options); var isDisabled = false; if(settings.SecondClassName === ''){isDisabled = true; } return this.each(function(){var $this = $(this); function inviewClass(){let currentPos = pageYOffset; if(currentPos > $this.offset().top - window.innerHeight + settings.visibleOffset){if(!$this.hasClass(settings.className)){$this.addClass(settings.className); if(!isDisabled){var thisTimeoutID = setTimeout(function(){$this.addClass(settings.secondClassName); clearTimeout(thisTimeoutID); }, settings.secondClassInterval); } } } } inviewClass(); $(window).on('resize', function(){inviewClass(); }); $(window).on('scroll', function(){inviewClass(); }); }) } })(jQuery);

const apm = [];
apm.body = document.querySelector('body');
apm.wrapper = document.querySelector('.l-main');
apm.header = document.querySelector('.siteheader');
//apm.container = apm.wrapper.lastElementChild;
apm.container = document.querySelector('.l-main');
apm.breakPoint = 600;
apm.headerNav = document.querySelector('.siteheader__nav');
apm.footer = document.querySelector('.sitefooter');
apm.anchorTarget = false;
apm.newsArchveLoadedTime = 0;


apm.initInviewClass = function(){

  setTimeout(()=> {
    $('.js-inview').inviewClass({
      visibleOffset: 200,
      secondClassInterval: 1000
    });
  }, 30);

  $('.js-inview-slidein').inviewClass({
    visibleOffset: 100,
    secondClassInterval: 1000
  });

}
apm.initInviewClass();


//randomフェードイン
apm.setRandomFadein = () => {

  let currentPos;

  if($('.js-inviewrandom').length < 1){
    return;
  }

  const delaySpeed = 100;

  function showItemsAtRandom(){

    currentPos = window.pageYOffset;
    const inviewItemsArray = [];

    $('.js-inviewrandom').each(function(){
      const $this = $(this);

      let thisOffsetTop = $(this).offset().top;

      if(currentPos + window.innerHeight > thisOffsetTop){
        if(!$this.hasClass('is-stored')){
          $this.addClass('is-stored')
          inviewItemsArray.push($this);
        }
      }
    })

    //配列をシャッフル （ダステンフェルドのシャッフル）
    for ( let i = inviewItemsArray.length; 1 < i; i--){
      let k = Math.floor(Math.random() * i);
      [inviewItemsArray[k], inviewItemsArray[i-1]] = [inviewItemsArray[i-1], inviewItemsArray[k]];
    }


    for(let i = 0; i < inviewItemsArray.length; i++){
      setTimeout(()=> {
        inviewItemsArray[i].addClass('is-visible');
        inviewItemsArray[i].addClass('delay-' + i);
      }, i * delaySpeed);
    }

  }

  showItemsAtRandom()

  let scrollTimerID;
  let scrollThinInterval = 300;

  $(window).on('scroll', ()=> {
    if(scrollTimerID) return;
    scrollTimerID = setTimeout(()=> {
      scrollTimerID = 0;
      showItemsAtRandom()
    }, scrollThinInterval);
  })

  /*
  const randomItemElems = document.querySelectorAll('.c-card');


  for(let i = 0; i < randomItemElems.length; i++){
    const thisItem = randomItemElems[i];
    const randomNum = Math.floor(Math.random() * (2 - 0 + 1) + 0);
    thisItem.classList.add('delay');
    thisItem.classList.add('delay--' + randomNum);
  }
  */

}

window.addEventListener('DOMContentLoaded', ()=> {
  apm.setRandomFadein();
})


apm.sendGA = function(){
  if(!apm.body.classList.contains('logged-in')){
    //console.log('計測を実行、開発中のため計測を停止中');
    //gtag('config', 'UA-65373678-2');
  } else {
    console.log('ログインユーザーは計測しない');
  }
}


apm.anchorTo = (targetID, callback) => {

  if(!targetID){
    return;
  }

  let $target = apm.container.querySelector('#' + targetID),
      waitTime = 0,
      thisOffset = -60;

  if(targetID == 'hero'){
    thisOffset = $('#hero').offset().top * -1;
  }

  if(window.innerWidth < apm.breakPoint){
    //thisOffset = -64;
  }


  $.Velocity($target, 'scroll', {
    duration: 600,
    easing: "easeInOutCubic",
    offset: thisOffset,
    delay: waitTime,
    complete: ()=> {
      if(callback){
        callback();
      }
      apm.anchorTarget = false;
    }
  })
}


apm.setHeroSize = ()=> {

  if(apm.container.querySelector('#hero') == null){
    return;
  }

  const heroSection = apm.container.querySelector('#hero')

  let lastWindowWidth = window.innerWidth;

  function setSectionSize(){
    heroSection.style.height = (window.innerHeight - $(heroSection).offset().top) + 'px';
    /*
    if(window.innerWidth < apm.breakPoint){
      heroSection.style.height = (window.innerHeight - $(heroSection).offset().top) + 'px';
    } else {
      heroSection.style.height = '';
    }*/
  }
  setSectionSize()


  let timer = null;
  window.addEventListener('resize', ()=> {
    clearTimeout( timer );
    timer = setTimeout(function() {

      if(window.innerWidth !== lastWindowWidth) {
        setSectionSize()
        lastWindowWidth = window.innerWidth;
      }

    }, 300 );
  });

}
window.addEventListener('DOMContentLoaded', ()=> {
  apm.setHeroSize();
})

/*
apm.initPageTop = () => {

  if(document.querySelector('.c-pagetop') == null) {
    return;
  }

  const pageTopElem = document.querySelector('.c-pagetop'),
        topElement = document.querySelector('#barba-wrapper');

  pageTopElem.addEventListener('click', (event)=> {
    event.preventDefault();
    Velocity(topElement, 'scroll', {
      duration: 800,
      easing: "easeInOutCubic",
      offset: 0,
      delay: 0,
      complete: ()=> {
        apm.anchorTarget = false;
      }
    })
  })
}
apm.initPageTop();
*/

apm.initAnchorlink = function(){

  //pagetop
  const pagetopTarget = document.querySelector('#pagetop-target');
  const pageTopArray = document.querySelectorAll('.js-pagetop');
  for(let i = 0; i < pageTopArray.length; i++){
    pageTopArray[i].addEventListener('click', (event)=> {
      event.preventDefault();
      $.Velocity(pagetopTarget, 'scroll', {
        duration: 600,
        easing: "easeInOutCubic",
      })
    })
  }


  if(apm.body.classList.contains('home')){
    const homePageTopArray = document.querySelectorAll('.siteheader__symbol a, .sitefooter__logo');
    for(let i = 0; i < homePageTopArray.length; i++){
      homePageTopArray[i].addEventListener('click', (event)=> {
        //console.log('here');
        event.preventDefault();
        apm.anchorTo('hero');
      })
    }
  }

  const anchorLinkArray = document.querySelectorAll('.js-anchor');
  for(let i = 0; i < anchorLinkArray.length; i++) {
    const thisLink = anchorLinkArray[i];
    const target = thisLink.href.split('#')[1];

    thisLink.addEventListener('click', (event)=> {
      //console.log(target)
      event.preventDefault();
      apm.anchorTo(target);
    })
  }


  if(location.hash){

    setTimeout(function(){
      $(window).scrollTop(0)
      const targetAnchor = location.hash.split('#')[1];
      apm.anchorTo(targetAnchor);
    }, 50);
  }

  //クリックしたURL(hash除く)が同じならアンカー移動
  $('a').on('click', function(event){
    const $this = $(this);
    if($this.attr('href').split('#')[0] == location.href.split('#')[0]){
      const targetAnchor = $this.attr('href').split('#')[1];
      if(targetAnchor){
        event.preventDefault();
        apm.closeMenu();
        apm.anchorTo(targetAnchor);
      }
    }
  })


}
apm.initAnchorlink();


const menuIcon = document.querySelector('.js-menu-icon'),
      menuBody = document.querySelector('.js-menu-body'),
      menuOverlay = document.querySelector('.js-menu-overlay'),
      menuContent = document.querySelector('.js-menu-content');

apm.initMenu = function(){
  apm.openMenu = function(){
    apm.body.classList.add('is-menu-open')
    menuBody.style.display = 'block';
    menuContent.style.opacity = 0;

    $.Velocity(menuOverlay, {
      translateX: [0, window.innerWidth * -1]
    }, {
      duration: 800,
      easing: 'easeOutExpo',
      begin: ()=> {
        setTimeout(()=> {
          $.Velocity(menuContent, {
            opacity: 1
          }, {
            duration: 600,
            easing: 'easeOutQuad',
          })
        }, 200);
      }
    })
  }

  /*
    メニューは左から入って右に抜ける動き
  */

  apm.closeMenu = function(){

    $.Velocity(menuContent, {
      opacity: 0
    }, {
      duration: 200,
      easing: 'easeOutQuad',
      begin: ()=> {
        apm.body.classList.remove('is-menu-open')
        setTimeout(()=> {
          $.Velocity(menuOverlay, {
            translateX: [window.innerWidth, 0]
          }, {
            duration: 600,
            easing: 'easeOutQuart',
            complete: ()=> {
              menuBody.style.display = 'none';
            }
          })
        }, 100);
      }
    })
  }

  menuIcon.addEventListener('click', ()=> {
    if(!apm.body.classList.contains('is-menu-open')){
      apm.openMenu();
    } else {
      apm.closeMenu();
    }
  });

}
apm.initMenu();


apm.initScrollFunc = ()=> {
  const header = document.querySelector('.siteheader'),
        footer = document.querySelector('.sitefooter');

  let scrolledOffset;

  if(window.innerWidth > apm.breakPoint){
    scrolledOffset = 50;
  } else {
    scrolledOffset = 50;
  }

  let currentPos = window.pageYOffset;

  function scrollFunc() {
    currentPos = window.pageYOffset;

    if(currentPos > scrolledOffset){
      if(!apm.body.classList.contains('is-scrolled')){
        apm.body.classList.add('is-scrolled');
      }
    } else {
      if(apm.body.classList.contains('is-scrolled')){
        apm.body.classList.remove('is-scrolled')
      }
    }

    if(currentPos > $(footer).offset().top - window.innerHeight){
      if(!apm.body.classList.contains('is-footer-visible')){
        apm.body.classList.add('is-footer-visible')
      }
    } else {
      if(apm.body.classList.contains('is-footer-visible')){
        apm.body.classList.remove('is-footer-visible')
      }
    }


    if(currentPos > $(footer).offset().top - window.innerHeight / 2){
      if(!apm.body.classList.contains('is-footer-half-visible')){
        apm.body.classList.add('is-footer-half-visible')
      }
    } else {
      if(apm.body.classList.contains('is-footer-half-visible')){
        apm.body.classList.remove('is-footer-half-visible')
      }
    }

  }
  scrollFunc()

   window.addEventListener('scroll', ()=> {
    scrollFunc()
  })

  window.addEventListener('resize', ()=> {
    if(window.innerWidth > apm.breakPoint){
      scrolledOffset = 50;
    } else {
      scrolledOffset = 50;
    }
  })

}
apm.initScrollFunc();



apm.initNavigations = ()=> {

  //カレント表示用に、barba-containerに data-type 属性を追加している。
  const currentPostType = apm.container.dataset.type,
        headerNavLinkElemArray = apm.headerNav.querySelectorAll('a');

  for(let i = 0; i < headerNavLinkElemArray.length; i++){
    const thisLink = headerNavLinkElemArray[i];
    thisLink.classList.remove('is-current');

    let thisText = thisLink.href.split('/');
    //console.log()
    thisText = thisText[thisText.length - 2];

    //console.log(thisText)
    //thisText = thisText.replace(/\s+/g, '-');

    if(currentPostType.indexOf(thisText) > -1){
      thisLink.classList.add('is-current')
    }
  }
}
window.addEventListener('DOMContentLoaded', ()=> {
  //apm.initNavigations();
})



//
apm.initHeroSlider = function(){

  const heroSliderElem = document.querySelector('.heroslider');

  if(heroSliderElem == null){
    return;
  }

  const animationSpeed = 1200,
        loopInterval = 4000;

  const heroSlider = new Swiper(heroSliderElem , {
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    allowTouchMove: false,
    speed: animationSpeed,
    autoplay: {
      delay: loopInterval,
      disableOnInteraction: false
    }
  })


  $('.swiper-slide-active').addClass('is-kenburns');

  heroSlider.on('slideChangeTransitionStart', ()=> {

    //スライドが始まる時にis-kenburnsクラスを付与。ループ用に複製されたスライドにも同様
    $('.swiper-slide-active').addClass('is-kenburns');
    $('.swiper-slide-duplicate-active').addClass('is-kenburns');

    //activeでないスライドのis-kenburnsクラスを外す
    setTimeout(function(){
      $('.heroslider__item').not('.swiper-slide-active, .swiper-slide-duplicate-active').removeClass('is-kenburns');
    }, animationSpeed + 100);
  })
}

window.addEventListener('DOMContentLoaded', ()=> {
  apm.initHeroSlider();
})

apm.setPartsLayout = function(){
  if(apm.container.dataset.page == 'home'){
    apm.body.classList.add('home')
  } else {
    apm.body.classList.remove('home')
  }

  //base-contentのh4の中をspanで囲う
  const titleWithUnderlineArray = document.querySelectorAll('.base-content h4');

  for(let i = 0; i < titleWithUnderlineArray.length; i++) {
    const thisTitle = titleWithUnderlineArray[i],
          thisText = thisTitle.innerHTML;

    thisTitle.innerHTML = '<span>' + thisText + '</span>';
  }

  //c-tableのfixセルを決める
  //最初のtrのtdの数と一致するtrの最初のtdには .is-first をつける
  const componentTableElms = document.querySelectorAll('.c-table');
  for(let i = 0; i < componentTableElms.length; i++) {
    const thisTable = componentTableElms[i];

    const thisTableTrElems = thisTable.querySelectorAll('tr');
    const thisTableRowLength = thisTableTrElems[0].querySelectorAll('td').length;

    for(let k = 0; k < thisTableTrElems.length; k++) {

      const thisTrTdElems = thisTableTrElems[k].querySelectorAll('td');

      if(thisTrTdElems.length == thisTableRowLength){

        thisTrTdElems[0].classList.add('is-first');
      }
    }

  }

  //a tag のhref="tel" をPCでは無効化
  $('a[href^="tel:"]').on('click', (event)=> {
    if(window.innerWidth > apm.breakPoint) {
      event.preventDefault();
      return false;
    }
  })

  //ページの最低高さ
  apm.wrapper.style.minHeight = window.innerHeight - apm.footer.clientHeight + 'px';
  window.addEventListener('resize', ()=> {
    apm.wrapper.style.minHeight = window.innerHeight - apm.footer.clientHeight + 'px';
  })

  //newsサイドバーの年別アコーディオン
  if(document.querySelector('.c-accordion') !== null){
    const accordion = document.querySelector('.c-accordion');

    accordionTitle = accordion.querySelector('.c-accordion__title');
    accordion.list = accordion.querySelector('.c-accordion__list');

    accordionTitle.addEventListener('click', ()=> {

      if(!accordion.classList.contains('is-open')){
        accordion.classList.add('is-open')
        $(accordion.list).stop().velocity('slideDown', {
          speed: 200,
          easing: 'easeOutQuad',
        })
      } else {
        accordion.classList.remove('is-open')
        $(accordion.list).stop().velocity('slideUp', {
          speed: 200,
          easing: 'easeOutQuad',
        })
      }
    })
  }



  //sidebarのローカルナビ カレント表示
  /*
  if(document.querySelector('.c-sidebarnav__list')){
    //base-content内のsectionをみる

    const baseSectionArray = document.querySelectorAll('.base-content section');
    const sidebarNavList = document.querySelectorAll('.c-sidebarnav__list a');
    let currentPos = window.pageYOffset;
    const offset = 100;
    let lastID;

    for(let i = 0; i < baseSectionArray.length; i++) {

      const thisSection = baseSectionArray[i];

      function scrollFunc(){

        currentPos = window.pageYOffset + offset;
        if(currentPos > $(thisSection).offset().top && currentPos < $(thisSection).offset().top + $(thisSection).height()){
          if(lastID !== thisSection.id){
            lastID = thisSection.id;
            for(let k = 0; k < sidebarNavList.length; k++){
              sidebarNavList[k].classList.remove('is-current');
              if(sidebarNavList[k].href.split('#')[1] == thisSection.id){
                sidebarNavList[k].classList.add('is-current');
              }
            }
          }
        }

      }
      $(window).on('scroll', function(){
        scrollFunc()
      })
    }
  }
  */

}

window.addEventListener('DOMContentLoaded', ()=> {
  apm.setPartsLayout();
});


apm.initCarousel = function(){

  if(document.querySelector('.l-overflowslider') !== null ) {

    const carouselArray = document.querySelectorAll('.l-overflowslider__list');

    for(let i = 0; i < carouselArray.length; i++) {

      const slideLength = carouselArray[i].querySelectorAll('.l-overflowslider__item').length;
      const nextArrow = document.querySelector('.l-overflowslider .swiper-button-next');

      const thisCarousel = new Swiper(carouselArray[i], {
        speed: 300,
        slidesPerView: 'auto',
        mousewheel: {
          releaseOnEdges: true,
          forceToAxis: true,
          sensitivity: 0.1,
          thresholdTime: .2,
          invert: true,
        },
        navigation: {
          nextEl: '.l-overflowslider__arrow--next-' + i,
          prevEl: '.l-overflowslider__arrow--prev-' + i,
        },
        pagination: {
          el: '.l-overflowslider__pagination-' + i,
          clickable: true
        },
        breakpoints: {
          600: {
            speed: 500,
          }
        }
      })

      //最後のスライドが見えている時にnextをクリックしたら最初に戻る
      thisCarousel.on('transitionEnd', function(){
        if(nextArrow.classList.contains('swiper-button-disabled')){
          nextArrow.classList.add('is-target-firstslide')
        } else {
          nextArrow.classList.remove('is-target-firstslide')
        }
      })

      nextArrow.addEventListener('click', ()=> {
        if(nextArrow.classList.contains('is-target-firstslide')){
          thisCarousel.slideTo(0, 600)
        }
      })

    }

  }

  if(document.querySelector('.baseslider') !== null ) {

    const carouselArray = document.querySelectorAll('.baseslider');

    for(let i = 0; i < carouselArray.length; i++) {


      /*
      概要
      スライダー画像の比率が混在してなければ（僅かな誤差は吸収する）
        何もしない

      スライダー画像の比率が混在していれば
        最も高い横位置画像（正方形含む）の比率に合わせる
          最大比率
            PC -> 3:2
            SP -> 1:1
      */

      const slideElems = carouselArray[i].querySelectorAll('.swiper-slide');

      let highestImgRatio = 0,
          highestSlideIndex = 0;

      let lastRatio = 0,
          isImgRatioMixed = false;

      const acceptableValue = 0.001;

      //1. 最も高い横位置画像（正方形含む）の比率を取得しておく
      //2. 画像の比率が混在しているか調べる。
      for(let k = 0; k < slideElems.length; k++){

        //読み込み速度が影響しないようにattributeからwidth/heightを取得する
        const imgElem = slideElems[k].querySelector('img');
        let thisRatio = imgElem.getAttribute('height') / imgElem.getAttribute('width');

        //1.
        if(thisRatio <= 1){
          if(thisRatio > highestImgRatio){
            highestImgRatio = thisRatio;
            highestSlideIndex = k;
          }
        }

        //2.
        if(k === 0){
          lastRatio = thisRatio;
        } else {
          //1つ前の画像の比率との差がacceptableValue以上になっていれば比率が混在しているとみなす
          if(Math.abs(thisRatio - lastRatio) > acceptableValue){
            isImgRatioMixed = true;
            //console.log('isMixed')
          }
          lastRatio = thisRatio;
        }
      }

      //スライドの高さを最も高い横位置画像（正方形含む）の比率に設定する
      //比率で計算するので画像の読み込みは待たない
      const limitRatioWide = 0.667,
            limitRatioSml = 1;

      function setSlideHeight(){
        //高さは表示領域幅 / もっとも高い画像の縦横比
        /*
        最大比率
          PC -> 3:2
          SP -> 1:1
        */
        let applingImgRatio = highestImgRatio;

        if(window.innerWidth > apm.breakPoint){
          if(applingImgRatio > limitRatioWide){
            applingImgRatio = limitRatioWide;
            //console.log('limiter wide');
          }
        } else {
          if(applingImgRatio > limitRatioSml){
            applingImgRatio = limitRatioSml;
            //console.log('limiter sml');
          }
        }

        const slideWidth = carouselArray[i].clientWidth,
              slideHeight = Math.round(slideWidth * applingImgRatio);

        for(let m = 0; m < slideElems.length; m++){
          const thisSlide = slideElems[m];
          //比率の値が0の時は縦位置画像のみかつ比率が違う時。その時は高さの調節をせずCSSの最大高さ800pxに任せる
          if(isImgRatioMixed && applingImgRatio > 0){
            thisSlide.style.height = slideHeight + 'px';
          }

          //1枚目以降のlazyloadを読み込む
          if(m > 0){
            const thisImg = thisSlide.querySelector('img'),
                  thisImgSrc = thisImg.dataset.src;
            if(!thisImg.getAttribute('src')){
              thisImg.setAttribute('src', thisImgSrc);
            }
          }
        }
      }
      setSlideHeight();

      const thisCarousel = new Swiper(carouselArray[i], {
        speed: 300,
        slidesPerView: 'auto',
        mousewheel: {
          releaseOnEdges: true,
          forceToAxis: true,
          sensitivity: 0.1,
          thresholdTime: .2,
          invert: true,
        },
        navigation: {
          nextEl: '.baseslider__arrow--next--' + i,
          prevEl: '.baseslider__arrow--prev--' + i,
        },
        pagination: {
          el: '.baseslider__pagination--' + i,
          clickable: true
        },
        breakpoints: {
          600: {
            speed: 500,
          }
        }
      })
      const nextArrow = document.querySelector('.baseslider__arrow--next--' + i);

      //最後のスライドが見えている時にnextをクリックしたら最初に戻る
      thisCarousel.on('transitionEnd', ()=> {
        if(nextArrow.classList.contains('swiper-button-disabled')){
          nextArrow.classList.add('is-target-firstslide')
        } else {
          nextArrow.classList.remove('is-target-firstslide')
        }
      })

      nextArrow.addEventListener('click', ()=> {
        if(nextArrow.classList.contains('is-target-firstslide')){
          thisCarousel.slideTo(0, 600)
        }
      })

      //リサイズ時にスライド高さを更新してスライドをアップデート
      let resizeTimer = false;
      window.addEventListener('resize', ()=> {
        if(resizeTimer !== false) {
          clearTimeout(resizeTimer);
        }
        resizeTimer = setTimeout(()=> {
          setSlideHeight();
          thisCarousel.update();
        }, 200);
      })

    }

  }

}
apm.initCarousel();


apm.initFadeSlider = function(){

  const fadeSliderArray = document.querySelectorAll('.basecontent-slider');

  for(let i = 0; i < fadeSliderArray.length; i++) {

    const slideLength = fadeSliderArray[i].querySelectorAll('.swiper-slide').length;
    //console.log(slideLength);

    if(slideLength < 2){
      return;
    }

    const thisSlider = new Swiper(fadeSliderArray[i], {
      speed: 1000,
      effect: 'fade',
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      }
      //slidesPerView: 'auto',
    })
  }

}
apm.initFadeSlider();

apm.heroImage = document.querySelector('.hero__visual img');

//ページ遷移でのローディングを管理
apm.initLoading = function(){

  function loadingSequence(){

    //ld.rootElem.classList.add('is-loading-start');

    setTimeout(()=> {
      //ld.rootElem.classList.add('is-loading-end');
    }, 1400);
  }

  if(apm.container.dataset.page === 'home'){

    if(ld.hasSessionData === 'yes'){
      //console.log('yes');
    } else {
      //console.log('no');
      sessionStorage.setItem('has-sessiondata', 'yes');
      //ld.rootElem.classList.remove('is-loading-hide');

      setTimeout(()=> {
        loadingSequence();
      }, 800);
    }
  }

}


//初回読み込みのローディングを管理
apm.endInitialLoading = function(){
  //console.log('endInitialLoading')

  //if()
  //console.log()
  if(!apm.heroImage) {
    return;
  }

  let minLoadingTime = 1400;

  apm.loadingEndTime = new Date();

  //minLoadingTime = minLoadingTime - (apm.loadingEndTime - ld.loadingStartTime)
  if(minLoadingTime < 0){
    minLoadingTime = 0;
  }
  /*
  setTimeout(()=> {
    imagesLoaded(apm.heroImage, ()=> {
      //console.log('imagesloaded');
      if(!ld.isinitialLoadingDone && apm.container.dataset.page === 'home'){
        //ld.isinitialLoadingDone = true;
        //ld.rootElem.classList.add('is-loading-end');
      }
    })
  }, minLoadingTime)
  */
  /*
  //console.log(apm.loadingEndTime - ld.loadingStartTime);
  if((apm.loadingEndTime - ld.loadingStartTime) < minLoadingTime){
    setTimeout(()=> {
      if(!ld.isinitialLoadingDone && apm.container.dataset.page === 'home'){
        ld.isinitialLoadingDone = true;
        ld.rootElem.classList.add('is-loading-end');
      }
    }, minLoadingTime - (apm.loadingEndTime - ld.loadingStartTime))
  } else {
    if(!ld.isinitialLoadingDone && apm.container.dataset.page === 'home'){
      ld.isinitialLoadingDone = true;
      ld.rootElem.classList.add('is-loading-end');
    }
  }*/

}

window.addEventListener('DOMContentLoaded', ()=> {
  apm.endInitialLoading();
})

if(apm.hasSessionData === 'yes'){
  //$('.loading').hide();
}


apm.loadInstagram = function(){

  if(apm.container.querySelector('#instagram-container') == null){
    return;
  }

  const $target = $('#instagram-container', apm.container),
        filePath = $target.data('url') + '/cmn/js/instagram.php';

  $.ajax({
    type: 'POST',
    url: filePath,
  }).done(function(response){
    $target.html(response);
    $('.js-inview-container', $target).inviewClass({
      visibleOffset: 200,
      secondClassInterval: 1000
    });

  }).fail(function(){

    console.log('ajax error');
    //$instagramTarget.after('<p class="align-c error-text">投稿を取得できませんでした。<br>しばらくしてから再度アクセスしてください。</p>');
  });

}
window.addEventListener('DOMContentLoaded', ()=> {
  apm.loadInstagram();
})



apm.initBarba = function() {

  apm.fadeOutTime = 300;

  Barba.Pjax.start();

  var FadeTransition = Barba.BaseTransition.extend({
    start: function() {

      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this));
    },

    fadeOut: function() {

      //let fadeTime = 300;
      //menu経由時はfadetimeを0に
      if(apm.body.classList.contains('is-menu-open')){
        apm.closeMenu();
      }

      $.Velocity(apm.footer, {
        opacity: 0,
      },{
        duration: apm.fadeOutTime,
        easing: 'easeOutQuad',
      })

      return $.Velocity(this.oldContainer, {
        opacity: 0,
      },{
        duration: apm.fadeOutTime,
        easing: 'easeOutQuad',
        complete: function(){

        }
      })
      $.promise();
    },

    fadeIn: function() {

      document.documentElement.classList.remove('is-fadein-done')

      var _this = this;
      var thisNewContainer = this.newContainer;

      this.oldContainer.style.display = 'none';

      $(window).scrollTop(0);
      apm.lazyload();
      apm.initparallax();

      let fadeTime = 1000;

      apm.body.classList.remove('error404');
      thisNewContainer.style.visibility = 'visible';
      thisNewContainer.style.opacity = '0';

      let pageBody = false;

      if(thisNewContainer.querySelector('.l-pagebody') !== null){
        pageBody = thisNewContainer.querySelector('.l-pagebody')
        pageBody.style.opacity = '0';
      }

      setTimeout(()=> {
        $.Velocity(thisNewContainer, {
          opacity: 1
        },{
          duration: 1000,
          easing: 'easeInOutQuad',
          begin: function(){

            _this.done();


            setTimeout(()=> {
              apm.showHideItem();
              apm.initInviewClass();
              if(!pageBody){
                return;
              }
              $.Velocity(pageBody, {
                opacity: 1
              },{
                duration: 1000,
                easing: 'easeInOutQuad',
              })
            }, 400);

          },
          complete: function(){
            document.documentElement.classList.add('is-fadein-done')
          }
        })

        $.Velocity(apm.footer, {
          opacity: 1,
        },{
          duration: 400,
          easing: 'easeOutQuad',
        })

      }, 300);




    }
  });

  //if($('.ua-ie, .ua-edge').length < 1){
    Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
    Barba.Pjax.preventCheck = function(evt, element) {

      if(element){

        apm.barbaLinkClicked = true;

        //WordPressの管理バーのリンクはPjaxを無効に
        if(element.classList.contains('ab-item')){
          return false;
        }
        if(element.classList.contains('no-barba')){
          return false;
        }
        //編集ボタンのリンクはPjaxを無効に
        if(element.classList.contains('editlink')){
          return false;
        }

        if($(element).parent().hasClass('menunav__item') || $(element).hasClass('sitemenu__logo')){
          apm.fadeOutTime = 100;
        } else {
          apm.fadeOutTime = 240;
        }


        // アンカーリンクであり同一ページでなければPjaxを有効に
        let url = location.protocol + '//' + location.host + location.pathname;
        let extract_hash = element.href.replace(/#.*$/,"");
        if (element.href.startsWith(location.protocol + '//' + location.host)) {
          if (element.href.indexOf('#') > -1 &&  extract_hash != url ){
            apm.anchorTarget = element.href.split('#')[1];
            return true;
          } else {
            const anchorTarget = element.href.split('#')[1];
            apm.anchorTo(anchorTarget);
          }
        }

        if (!Barba.Pjax.originalPreventCheck(evt, element)) {
          return false;
        }
      } else {
        return false;
      }
      return true;

    };



  Barba.Pjax.getTransition = function() {
    return FadeTransition;
  };

  Barba.Dispatcher.on('newPageReady', function() {

    apm.container = apm.wrapper.lastElementChild;

    apm.initBuynow();
    apm.loadInstagram();
    apm.initLoadMore(apm.barbaLinkClicked);
    apm.setPartsLayout();

    apm.initAnchorlink();
    apm.initLoading();
    apm.setHeroSize();

    //ブラウザバックならinview効果をOFF
    if(!apm.barbaLinkClicked){
      $('.js-inview, .js-inview-large, .js-inview-container, .js-inview-fadein').addClass('is-inview')
    }
    apm.barbaLinkClicked = false;

  });

  Barba.Dispatcher.on('transitionCompleted', function() {
    //console.log('transitionCompleted');
    apm.anchorTo(apm.anchorTarget);
    apm.sendGA();

  });
}
//apm.initBarba();


apm.initialFadeIn = function(){

  //サイトホーム以外
  /*
  .l-main .l-pagebody をopacity: 0にする
  ここでフェードイン
  */
  //console.log(document.documentElement.classList.contains('is-loading-hide'))

  if(apm.body.classList.contains('home') && !document.documentElement.classList.contains('is-loading-hide')) {
    //console.log('return')
    document.querySelector('.l-main').style.opacity = 1;
    return;
  }

  const layoutMain = document.querySelector('.l-main'),
        layoutPagebody = document.querySelector('.l-pagebody');

  setTimeout(()=> {
    $.Velocity(layoutMain, {
      opacity: 1
    },{
      duration: 1000,
      easing: 'easeInOutQuad',
      begin: function(){

        setTimeout(()=> {
          //apm.showHideItem();
          apm.initInviewClass();
          if(!layoutPagebody){
            return;
          }
          $.Velocity(layoutPagebody, {
            opacity: 1
          },{
            duration: 1000,
            easing: 'easeInOutQuad',
          })
        }, 400);

      },
      complete: function(){
        document.documentElement.classList.add('is-fadein-done')
      }
    })

  }, 0);
}

apm.initMediaAdjust = function(){
  // メディアとカタカナに強制的になってしまうのを上書きでMedia と変更する処理

  function changeText(className, newText) {
    // 指定したクラス名を持つ要素を取得
    var elements = document.querySelectorAll(className);
    elements.forEach(function(element) {
      element.textContent = newText;
    });
  }
  // changeText関数を呼び出してテキストを変更
  changeText(".menu-item-object-mediainfo a", "Media");

  // テキストを変更する関数
  function changeCardText() {
    // c-card__metainfo内のすべての要素を取得
    var metainfos = document.querySelectorAll(".c-card__metainfo");
    // metainfosが空でないことを確認
    if (metainfos.length > 0) {
      // 各要素に対してループ
      metainfos.forEach(function(metainfo) {
        var typeElement = metainfo.querySelector(".type");
        if (typeElement && typeElement.textContent.trim() === "メディア") {
          typeElement.textContent = "Media";
        }
      });
    }
  }
  // changeText関数を呼び出してテキストを変更
  changeCardText();

  var pagetitle = document.querySelector(".l-dirtitle");
  if (pagetitle) {
    if (pagetitle && pagetitle.textContent.trim() === "メディア") {
      pagetitle.textContent = "Media";
    }
  } else {
    console.log(pagetitle);
  }

}

window.addEventListener('DOMContentLoaded', ()=> {
  apm.body.classList.add('is-ready')
  //apm.sendGA();
  apm.initMediaAdjust();
  apm.initialFadeIn();
  //apm.webfontLoad();
})
